import { useCallback, useContext, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { Container, Row, Col, Button, UncontrolledCollapse, CardBody } from 'reactstrap';
import Icon from 'components/common/icon';
import RangeSelector from 'components/general/RangeSelector';
import { useSite } from 'context/site';
import { yearRangeVar, activeScenarioVar } from 'common/cache';
import GoalSelector from 'components/general/GoalSelector';
import ScenarioSelector from 'components/general/ScenarioSelector';
import NormalizationWidget from './NormalizationWidget';
import GoalOutcomeBar from 'components/general/GoalOutcomeBar';
import ScenarioBadge from 'components/common/ScenarioBadge';
import GlobalParameters from 'components/general/GlobalParameters';
import ActionsSummary from './ActionsSummary';
import { useInstance } from 'common/instance';
import { useTranslation } from 'next-i18next';
const SettingsHeader = styled.div`
  padding: 1rem 0;
  background-color: ${props => props.theme.graphColors.grey010};

  h2 {
    font-size: ${props => props.theme.fontSizeLg};
  }
`;
const SettingsContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 0 6rem;
  background-color: ${props => props.theme.graphColors.grey020};
  overflow-y: scroll;
`;
const DisplaySettings = styled.div``;
const SettingsSection = styled.div`
  margin-bottom: 1rem;
`;
const Widget = styled.div`
  margin-bottom: 2rem;
`;
const AccordionHeader = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  border-radius: 0;
  //border-bottom: 2px solid ${props => props.theme.graphColors.grey050};
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: ${props => props.theme.graphColors.grey000};
  color: ${props => props.theme.graphColors.grey090};
  //box-shadow: 0 0 4px 4px rgba(20,20,20,0.05);
  //border-top: 2px solid ${props => props.theme.graphColors.grey050};

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.graphColors.grey010} !important;
    color: ${props => props.theme.graphColors.grey090} !important;
  }
`;
const Card = styled.div`
  background-color: ${props => props.theme.graphColors.grey000};
  padding: 1rem;
`;
const CompleteSettings = props => {
  if (!process.browser) {
    return null;
  }
  const site = useSite();
  const instance = useInstance();
  const activeScenario = useReactiveVar(activeScenarioVar);
  const hasGlobalParameters = site.parameters.find(param => param.isCustomizable) !== undefined;
  const hasNormalizations = site.availableNormalizations.length > 0;

  // State of display settings
  // Year range
  const yearRange = useReactiveVar(yearRangeVar);
  const setYearRange = useCallback((newRange: [number, number]) => {
    yearRangeVar(newRange);
  }, [yearRangeVar]);
  const {
    t
  } = useTranslation();

  // Target
  const nrGoals = instance.goals.length;

  // Normalization
  const availableNormalizations = site.availableNormalizations;
  return <>
      <SettingsHeader data-sentry-element="SettingsHeader" data-sentry-source-file="CompleteSettings.tsx">
        <Container fluid="lg" data-sentry-element="Container" data-sentry-source-file="CompleteSettings.tsx">
          <h2>{t('all-settings')}</h2>
        </Container>
      </SettingsHeader>
      <SettingsContent data-sentry-element="SettingsContent" data-sentry-source-file="CompleteSettings.tsx">
        <Container fluid="lg" data-sentry-element="Container" data-sentry-source-file="CompleteSettings.tsx">
          <SettingsSection data-sentry-element="SettingsSection" data-sentry-source-file="CompleteSettings.tsx">
            <AccordionHeader color="primary" id="display-toggler" data-sentry-element="AccordionHeader" data-sentry-source-file="CompleteSettings.tsx">
              <h4>{t('display')}</h4>
              <Icon name="angleDown" width="24px" height="24px" data-sentry-element="Icon" data-sentry-source-file="CompleteSettings.tsx" />
            </AccordionHeader>
            <UncontrolledCollapse toggler="#display-toggler" defaultOpen data-sentry-element="UncontrolledCollapse" data-sentry-source-file="CompleteSettings.tsx">
              <Card data-sentry-element="Card" data-sentry-source-file="CompleteSettings.tsx">
                <CardBody data-sentry-element="CardBody" data-sentry-source-file="CompleteSettings.tsx">
                  <DisplaySettings data-sentry-element="DisplaySettings" data-sentry-source-file="CompleteSettings.tsx">
                    <Row data-sentry-element="Row" data-sentry-source-file="CompleteSettings.tsx">
                      <Col md="5" data-sentry-element="Col" data-sentry-source-file="CompleteSettings.tsx">
                        <h5>{t('comparing-years')}</h5>
                        <RangeSelector min={site.minYear} max={site.maxYear} defaultMin={yearRange[0]} defaultMax={yearRange[1]} referenceYear={instance.referenceYear ?? site.referenceYear} handleChange={setYearRange} data-sentry-element="RangeSelector" data-sentry-source-file="CompleteSettings.tsx" />
                      </Col>
                      {hasNormalizations && <Col md="3">
                          <h5>{t('normalization')}</h5>
                          <NormalizationWidget availableNormalizations={availableNormalizations} />
                        </Col>}
                      {nrGoals > 1 && <Col md="4">
                          <h5>{t('target-climate')}</h5>
                          <GoalSelector />
                        </Col>}
                    </Row>
                  </DisplaySettings>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </SettingsSection>
          <SettingsSection data-sentry-element="SettingsSection" data-sentry-source-file="CompleteSettings.tsx">
            <AccordionHeader color="primary" id="scenario-toggler" data-sentry-element="AccordionHeader" data-sentry-source-file="CompleteSettings.tsx">
              <h4>
                {t('scenario')}: {activeScenario.name}
              </h4>
              <Icon name="angleDown" width="24px" height="24px" data-sentry-element="Icon" data-sentry-source-file="CompleteSettings.tsx" />
            </AccordionHeader>
            <UncontrolledCollapse toggler="#scenario-toggler" defaultOpen data-sentry-element="UncontrolledCollapse" data-sentry-source-file="CompleteSettings.tsx">
              <Card data-sentry-element="Card" data-sentry-source-file="CompleteSettings.tsx">
                <CardBody data-sentry-element="CardBody" data-sentry-source-file="CompleteSettings.tsx">
                  <Widget data-sentry-element="Widget" data-sentry-source-file="CompleteSettings.tsx">
                    <h5>{t('change-scenario')}</h5>
                    <ScenarioSelector data-sentry-element="ScenarioSelector" data-sentry-source-file="CompleteSettings.tsx" />
                  </Widget>
                  <Widget data-sentry-element="Widget" data-sentry-source-file="CompleteSettings.tsx">
                    <h5>{t('actions')}</h5>
                    <ActionsSummary activeScenario={activeScenario} data-sentry-element="ActionsSummary" data-sentry-source-file="CompleteSettings.tsx" />
                  </Widget>
                  <Widget data-sentry-element="Widget" data-sentry-source-file="CompleteSettings.tsx">
                    {hasGlobalParameters && <>
                        <h5>Global settings</h5>
                        <GlobalParameters parameters={site.parameters} />
                      </>}
                  </Widget>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </SettingsSection>
          <SettingsSection data-sentry-element="SettingsSection" data-sentry-source-file="CompleteSettings.tsx">
            <GoalOutcomeBar data-sentry-element="GoalOutcomeBar" data-sentry-source-file="CompleteSettings.tsx" />
          </SettingsSection>
        </Container>
      </SettingsContent>
    </>;
};
export default CompleteSettings;