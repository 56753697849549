import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { InstanceGoal, useInstance } from 'common/instance';
import { activeGoalVar } from 'common/cache';
const StyledDropdown = styled(Dropdown)`
  //min-width: 200px;

  .btn {
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.9rem;
    padding: ${({
  theme
}) => theme.spaces.s050};

    &:focus {
      box-shadow: 0 0 0 0.25rem ${props => props.theme.inputBtnFocusColor};
    }
  }
`;
const StyledDropdownLabel = styled.span`
  display: block;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const StyledSublabel = styled.span`
  display: block;
  font-style: italic;
  font-size: ${({
  theme
}) => theme.fontSizeSm};
  line-height: ${({
  theme
}) => theme.lineHeightSm};
`;
const DropdownLabel = styled.div`
  font-size: 0.8rem;
`;
const GoalSelector = () => {
  const {
    t
  } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const instance = useInstance();
  const activeGoal = useReactiveVar(activeGoalVar);
  const selectGoal = useCallback((goal: InstanceGoal) => {
    activeGoalVar(goal);
  }, []);
  return <StyledDropdown isOpen={dropdownOpen} toggle={toggle} data-sentry-element="StyledDropdown" data-sentry-component="GoalSelector" data-sentry-source-file="GoalSelector.tsx">
      <DropdownLabel data-sentry-element="DropdownLabel" data-sentry-source-file="GoalSelector.tsx">{t('target')}</DropdownLabel>
      <DropdownToggle color="light" data-sentry-element="DropdownToggle" data-sentry-source-file="GoalSelector.tsx">
        <StyledDropdownLabel data-sentry-element="StyledDropdownLabel" data-sentry-source-file="GoalSelector.tsx">{activeGoal?.label}</StyledDropdownLabel>
      </DropdownToggle>
      <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="GoalSelector.tsx">
        <DropdownItem header data-sentry-element="DropdownItem" data-sentry-source-file="GoalSelector.tsx">{t('change-target')}</DropdownItem>
        {instance.goals.map(goal => <DropdownItem disabled={goal.disabled} key={goal.id} active={goal.id === activeGoal?.id} onClick={() => selectGoal(goal)}>
            <span>{goal.label}</span>
            {goal.disabled && <StyledSublabel>{t('coming-soon')}</StyledSublabel>}
          </DropdownItem>)}
      </DropdownMenu>
    </StyledDropdown>;
};
export default GoalSelector;