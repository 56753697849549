import { gql, useMutation, useQuery, NetworkStatus } from '@apollo/client';
import styled from 'styled-components';
import { Row, Col, FormGroup, Label, Input, Button, InputGroup, FormFeedback } from 'reactstrap';
import { GetParametersQuery, SetNormalizationMutation, SetNormalizationMutationVariables } from 'common/__generated__/graphql';
import { GET_PARAMETERS } from 'queries/getParameters';
import { useTranslation } from 'react-i18next';
const SwitchWrapper = styled.div`
  max-width: 160px;
  .form-label {
    margin-bottom: 0;
    line-height: 1;
    font-size: 0.8rem;
  }
`;
const SET_NORMALIZATION_MUTATION = gql`
  mutation SetNormalizationFromWidget($id: ID) {
    setNormalizer(id: $id) {
      ok
    }
  }
`;
type NormalizationWidgetProps = {
  availableNormalizations: GetParametersQuery['availableNormalizations'];
};
function NormalizationWidget(props: NormalizationWidgetProps) {
  const {
    t
  } = useTranslation();
  const {
    loading,
    error,
    data,
    previousData,
    refetch,
    networkStatus
  } = useQuery<GetParametersQuery>(GET_PARAMETERS, {
    notifyOnNetworkStatusChange: true
  });
  const [setNormalization, {
    data: mutationData,
    loading: mutationLoading,
    error: mutationError
  }] = useMutation<SetNormalizationMutation, SetNormalizationMutationVariables>(SET_NORMALIZATION_MUTATION, {
    refetchQueries: 'active'
  });
  if (loading && !previousData || !data || !data.parameters) {
    return <>-</>;
  }
  if (error) {
    return <>
        <div>{t('error-loading-data')}</div>
      </>;
  }
  const {
    availableNormalizations
  } = data;
  if (!availableNormalizations.length) return null;
  const norm = availableNormalizations[0];
  const label = t('normalize-by', {
    node: norm.label
  });
  return <SwitchWrapper data-sentry-element="SwitchWrapper" data-sentry-component="NormalizationWidget" data-sentry-source-file="NormalizationWidget.tsx">
      <FormGroup switch data-sentry-element="FormGroup" data-sentry-source-file="NormalizationWidget.tsx">
        <Label for={norm.id} data-sentry-element="Label" data-sentry-source-file="NormalizationWidget.tsx">{label}</Label>
        <Input disabled={mutationLoading} type="switch" role="switch" id={norm.id} name={norm.id} checked={norm.isActive} onChange={e => {
        setNormalization({
          variables: {
            id: norm.isActive ? null : norm.id
          }
        });
      }} data-sentry-element="Input" data-sentry-source-file="NormalizationWidget.tsx" />
      </FormGroup>
    </SwitchWrapper>;
}
export default NormalizationWidget;